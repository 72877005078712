<template>
  <div>
    <div class="row mb-5 d-flex justify-content-center">
      <div
        class="col-xl-4 col-md-6 col-12 d-flex align-items-center flex-column"
      >
        <img
          class="w-25-p"
          src="https://carogusto-live.batix.ch/pic/18B89CD8B48.png"
          alt=""
        />
        <span class="text-dark-blue mt-3"
          >Welcome to the Carogusto Partnerportal!</span
        >
      </div>
    </div>
    <div class="row mb-4 d-flex justify-content-center">
      <div class="col-xl-4 col-md-6 col-12">
        <div class="input-group">
          <span class="login-icon flex-center bg-white text text-dark-blue">
            <font-awesome-icon icon="fa-regular fa-user" />
          </span>
          <input
            type="text"
            name="username"
            placeholder="Email"
            aria-describedby="username"
            autofocus
            autocapitalize="off"
            spellcheck="false"
            autocorrect="off"
            v-model="username"
            @keydown.enter="login"
          />
        </div>
      </div>
    </div>
    <div class="row mb-4 d-flex justify-content-center">
      <div class="col-xl-4 col-md-6 col-12">
        <div class="input-group">
          <span class="login-icon flex-center bg-white text text-dark-blue">
            <font-awesome-icon icon="fa-regular fa-key" />
          </span>
          <input
            :type="showpassword ? 'text' : 'password'"
            name="password"
            placeholder="Password"
            aria-describedby="password"
            autocapitalize="off"
            spellcheck="false"
            autocorrect="off"
            v-model="password"
            @keydown.enter="login"
          />
          <span
            class="show-password-icon text-dark-blue"
            @click="toggleShowPassword"
          >
            <font-awesome-icon
              class="fa-fw"
              v-if="!showpassword"
              icon="fa-light fa-eye"
            />
            <font-awesome-icon
              class="fa-fw"
              v-if="showpassword"
              icon="fa-light fa-eye-slash"
            />
          </span>
        </div>
      </div>
    </div>
    <div v-if="incorrectInput" class="row mb-4 d-flex justify-content-center">
      <div class="col-xl-4 col-md-6 col-12">
        <div class="text-danger text-bold text-center fs-12">
          Email address or password is incorrect.
        </div>
      </div>
    </div>
    <div class="row mb-4 d-flex justify-content-center">
      <div class="col-xl-4 col-md-6 col-12">
        <button
          class="btn app-btn w-100"
          @click="login"
          :disabled="!username || !password"
        >
          Login
        </button>
      </div>
    </div>
    <div class="row mb-4 d-flex justify-content-center">
      <div class="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
        <div class="cta cta-bold fs-14" @click="setShowForgotPasswordComponent">
          Forgot your Password?
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api.js";
import axios from "axios";

export default {
  name: "LoginInput",
  components: {},
  mixins: [],
  data() {
    return {
      username: "",
      password: "",
      showpassword: false,
      incorrectInput: false,
    };
  },
  computed: {
    loginUrl() {
      if (process.env.NODE_ENV === "development") {
        return `/login/`;
      } else {
        return `${process.env.VUE_APP_SERVER_URL}/login/`;
      }
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    async login() {
      try {
        let password = encodeURIComponent(this.password);
        await axios.post(
          `${this.loginUrl}?username=${this.username}&password=${password}`,
          null
        );
        Api.get("user/").then((response) => {
          let user = response.data;
          let page = null;

          if (user?.role?.id) {
            if (user?.role?.id === "1889B2E8093") {
              //ADMIN
              page = this.$router.resolve({ name: "companies" });
              window.location.href = page.href;
            } else if (user?.role?.id === "188AEA750CF" && user?.company?.id) {
              //MANUFACTURER
              page = this.$router.resolve({
                name: "company",
                params: { id: user?.company?.id },
              });
              window.location.href = page.href;
            } else if (user?.role?.id === "188AEA7CD44") {
              //PRODUCT DEVELOPER
              page = this.$router.resolve({ name: "products" });
              window.location.href = page.href;
            } else if (user?.role?.id === "188AEA80E7A") {
              //PRODUCER
              page = this.$router.resolve({ name: "publishedProducts" });
              window.location.href = page.href;
            } else {
              page = this.$router.resolve({ name: "profile" });
              window.location.href = page.href;
            }
          } else {
            page = this.$router.resolve({ name: "profile" });
            window.location.href = page.href;
          }
        });
      } catch (error) {
        this.incorrectInput = true;
        console.log(error);
      }
    },

    toggleShowPassword() {
      this.showpassword = !this.showpassword;
    },
    setShowForgotPasswordComponent() {
      this.$emit("setShowForgotPasswordComponent");
    },
  },
};
</script>

<style lang="scss"></style>
