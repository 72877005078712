<template>
  <div class="w-100-p">
    <div class="pos-r">
      <div class="background-image" />
      <div class="bg-overlay" />
    </div>

    <div class="login-container bg-blue-transparent">
      <login-input
        v-if="showLoginInputComponent"
        @setShowForgotPasswordComponent="setShowForgotPasswordComponent"
      />
      <login-forgot-password
        v-if="showForgotPasswordComponent"
        @setShowLoginInputComponent="setShowLoginInputComponent"
      />
    </div>
  </div>
</template>
	  
  <script>
import LoginInput from "@/components/login/LoginInput";
import LoginForgotPassword from "@/components/login/LoginForgotPassword";

export default {
  name: "Login",
  components: {
    LoginInput,
    LoginForgotPassword,
  },
  mixins: [],
  data() {
    return {
      showLoginInputComponent: true,
      showForgotPasswordComponent: false,
      maxHeight: 0,
    };
  },
  computed: {},
  watch: {},

  created() {
    this.$emit("setNavbarVisibility", false);
  },
  mounted() {},
  methods: {
    setShowLoginInputComponent() {
      this.showForgotPasswordComponent = false;
      this.showLoginInputComponent = true;
    },
    setShowForgotPasswordComponent() {
      this.showLoginInputComponent = false;
      this.showForgotPasswordComponent = true;
    },
  },
};
</script>
	  
	  <style lang="scss">
.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(https://carogusto-live.batix.ch/pic/18B89CEB87A.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -2;
}

.bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: -1;
}
</style>
	  