import axios from "axios";

let publicApiConfig = {
	baseURL: process.env.VUE_APP_PUBLICAPI_URL,
	withCredentials: true
};

const PublicApi = axios.create(publicApiConfig);

PublicApi.interceptors.response.use(
	function (response) {
		return response;
	},
);

export default PublicApi;
