<template>
	<div>
		<div class="row mb-5 d-flex justify-content-center">
      <div
        class="col-xl-4 col-md-6 col-12 d-flex align-items-center flex-column"
      >
        <img
          class="w-25-p"
          src="https://carogusto-live.batix.ch/pic/18B89CD8B48.png"
          alt=""
        />
      </div>
    </div>
	  <div v-if="!mailSent">
		<div class="row mb-4 d-flex justify-content-center">
		  <div class="col-xl-4 col-md-6 col-12">
			<p class="text-dark-blue text-center">
				To reset your password, please enter the email address linked with your account.
			</p>
		  </div>
		</div>
		<div class="row mb-4 d-flex justify-content-center">
		  <div class="col-xl-4 col-md-6 col-12">
			<div class="input-group">
			  <span class="login-icon flex-center bg-white text text-dark-blue">
				<font-awesome-icon icon="fa-regular fa-user" />
			  </span>
			  <input
				type="text"
				name="email"
				placeholder="Email"
				aria-describedby="email"
				autofocus
				autocapitalize="off"
				spellcheck="false"
				autocorrect="off"
				v-model="emailadresse"
			  />
			</div>
		  </div>
		</div>
		<div class="row mb-4 d-flex justify-content-center">
		  <div class="col-xl-4 col-md-6 col-12">
			<button class="btn app-btn w-100" @click="sendMail" :disabled="!validEmail">
			  Reset password
			</button>
		  </div>
		</div>
		<div class="row d-flex justify-content-center">
		  <div class="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
			<div @click="setShowLoginInputComponent" class="cta cta-bold fs-14">
			  Back to Login
			</div>
		  </div>
		</div>
	  </div>
	  <div v-if="mailSent">
		<div
		  class="row mb-4 d-flex justify-content-center align-items-center flex-column"
		>
		  <div class="col-xl-4 col-md-6 col-12 text-center mb-4">
			<font-awesome-icon
			  class="text-success fs-35"
			  icon="fa-regular fa-shield-check"
			/>
		  </div>
		  <div class="col-xl-4 col-md-6 col-12 text-center text-dark-blue">
			<p class="fs-14">
			  We have sent an email to
			  <span class="text-bold text-dark-blue">{{ emailadresse }}</span>
			</p>
		  </div>
		</div>
		<div
		  class="row mb-4 d-flex justify-content-center align-items-center flex-column"
		>
		<p class="text-dark-blue fs-14">
			No email received?
				<ul>
				<li>Check your spam folder</li>
				<li>Verify your information</li>
				<li>Please wait for 15 minutes before trying again</li>
				</ul>
		</p>
	  </div>
	  <div class="row d-flex justify-content-center">
		  <div class="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
			<div @click="setShowLoginInputComponent" class="cta cta-bold fs-14">
			 Back to Login
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </template>
		  
	  <script>
		import PublicAPI from "@/PublicApi";

export default {
  name: "LoginForgotPassword",
  components: {},
  mixins: [],
  data() {
    return {
      emailadresse: "",
      mailSent: false,
    };
  },
  computed: {
    validEmail() {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(this.emailadresse);
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    setShowLoginInputComponent() {
      this.$emit("setShowLoginInputComponent");
    },
	sendMail(){
		      PublicAPI.post("forgotpw/" + "?email=" + this.emailadresse, ).then((response) => {
        if (response?.status === 202) {
          this.$notify({
            type: "error",
            title: "Action failed",
            text: `Product could not be published. The name "${this.product.name}" already exists.`,
            duration: 10000,
          });
        } else {
          this.$notify({
            type: "success",
            title: "Action Successful",
            text: "Product successfully published.",
            duration: 8000,
          });

        }
      });
		this.mailSent = true;
	}
  },
};
</script>
		  
<style lang="scss">
</style>
		  